import Vue from "vue";
import Vuex from "vuex";
import API from "@/api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    slider_courses: "",
    courses: "",
  },
  getters: {},
  mutations: {
    SET_SLIDER_COURSES(store, payload) {
      store.slider_courses = payload;
    },
    SET_COURSES(store, payload) {
      store.courses = payload;
    },
  },
  actions: {
    loadSliderCourses({ commit }) {
      return new Promise((resolve, reject) => {
        API.get("api/courses/slider")
          .then((response) => {
            commit("SET_SLIDER_COURSES", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    loadCourses({ commit }) {
      return new Promise((resolve, reject) => {
        API.get("api/courses")
          .then((response) => {
            commit("SET_COURSES", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    loadCourseInfo(_, data) {
      return new Promise((resolve, reject) => {
        API.get(`api/course/${data}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    payCourse(_, data) {
      return new Promise((resolve, reject) => {
        API.post(`api/order`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  modules: {},
});
