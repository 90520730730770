import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueScrollTo from "vue-scrollto";

import sklonyator from "./plugins/sklonyator";

import "./scss/app.scss";
import store from "./store";

Vue.config.productionTip = false;

Vue.use(VueScrollTo);
Vue.use(sklonyator);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
