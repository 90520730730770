import axios from "axios";

let baseURL = process.env.VUE_APP_BACKEND;

fetch(`/`).then((data) => {
  if (data.headers.has("X-BASE-URL")) {
    API.defaults.baseURL = data.headers.get("X-BASE-URL");
    window.baseURL = data.headers.get("X-BASE-URL");
  }
});

const API = axios.create({
  baseURL: baseURL,
});
export default API;
